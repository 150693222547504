

























import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseButtonGroup from '../base/BaseButtonGroup.vue'

type ColorType = 'gray' | 'white'

@Component({
  name: 'SelectButtonGroup',
  components: {
    BaseButtonGroup,
  },
})
export default class SelectButtonGroup extends Vue {
  @Prop({ default: 'gray' })
  color!: ColorType

  @Prop({ default: false })
  fluid!: boolean

  @Prop({ default: false })
  disabled!: boolean

  @Prop({ default: () => [], type: Array })
  items!: []

  @Prop()
  value!: string

  select(id: string): void {
    this.$emit('input', id)
  }
}
