var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g(_vm._b({staticClass:"button-group",class:[
    {
      'button--fluid': _vm.fluid,
      'button-group--white': _vm.color === 'white',
      'button-group--gray': _vm.color === 'gray',
    },
    _vm.classes ]},'div',Object.assign({}, _vm.$attrs,
    {disabled: _vm.isDisabled}),false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }