import axios from '@/api/axios'
import {
  EventType,
  CallInterface,
  CallbackInterface,
  EventsResponseInterface,
} from './types'

class EventsService {
  async getEvents(
    page: Nullable<number> = null,
    type: Nullable<EventType> = null
  ): Promise<EventsResponseInterface> {
    try {
      const response = await axios.get('api/b2b/v1/events', {
        params: { page, type },
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getEvents', error.message, error.response?.data)
      }
      throw error
    }
  }

  async getEventDetails(
    type: EventType,
    id: number
  ): Promise<CallInterface | CallbackInterface> {
    try {
      const response = await axios.get(`api/b2b/v1/events/${type}/${id}`)
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getEventDetails', error.message, error.response?.data)
      }
      throw error
    }
  }

  isServiceError = axios.isAxiosError
}

export default new EventsService()
