import axios from '@/api/axios'

class BannerService {
  async getBanner(
    siteId = 1,
  ): Promise<any> {
    try {
      const response = await axios.get(`api/common/v1/sites/${siteId}/banner`, {
      })
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getBanner', error.message, error.response?.data)
      }
      throw error
    }
  }

  async clickBanner(
    siteId = 1,
  ): Promise<any> {
    try {
      const response = await axios.post(`api/common/v1/sites/${siteId}/click`, {})
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('clickOnBannerBooking', error.message, error.response?.data)
      }
      throw error
    }
  }

  isServiceError = axios.isAxiosError
}

export default new BannerService()
