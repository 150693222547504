















































import { Component, Vue } from 'vue-property-decorator';
import breakpoints from '@/plugins/breakpoints';
import BannerService from "@/core/services/BannerService";

@Component({
    name: 'TopBanner',
})
export default class TopBanner extends Vue {

    image = ""
    image_mobile = ""
    sign = ""
    url = ""
    id = 1
    isShowPopover =  false
    advertiser = {}

    async clickOnButton() {
       const response = await BannerService.clickBanner(this.id);
    }

    tooglePopover() {
        this.isShowPopover = !this.isShowPopover;
    }

    public async setData() {
        const banners: any = await BannerService.getBanner(9);
        if (banners && banners.top && banners.top.image) {
            this.image = banners.top.image;
            this.sign = banners.top.sign;
            this.url = banners.top.url;
            this.id = banners.top.id;
            this.image_mobile = banners.top.image_mobile;
            if (banners.top.advertiser.name) {
            this.advertiser = banners.top.advertiser;
            }
        }
    }

    get isMobile(): boolean {
        return breakpoints.width < 768
    }

    mounted() {
      this.setData();
    }

}
